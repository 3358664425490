import React, { useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import { Row, Col } from 'react-bootstrap';
import { Link } from '@reach/router';

export default function Index() {
  const [regions, setRegions] = useState([]);
  const [places, setPlaces] = useState([]);
  const q = gql`
    query {
      allPlaces {
        _key
        name
        slug
        region
        address
      }
    }
  `;

  const { data, loading, error } = useQuery(q);

  if (data && places.length < 1) {
    console.log(data);
    setPlaces(data.allPlaces);
  }

  return (
    <>
      <Row>
        <Col md={9} lg={10}>
          <h1>Browse Places</h1>
        </Col>
        <Col md={3} lg={2}>
          <a href="/places/create/" className="btn btn-primary" title="Create new place">
            + add new
          </a>
        </Col>
      </Row>
      <Row>
        {places.map((p, k) => (
          <Col key={k} md={4} lg={3}>
            <div
              style={{
                padding: '1.1rem',
                border: '1px solid',
                backgroundColor: '#fff',
                height: '180px',
                marginBottom: '10px',
              }}
            >
              <p style={{ paddingBottom: 0, marginBottom: 0 }}>
                <strong>{p.name}</strong>
              </p>
              <p>{p.address}</p>
              <p>
                <a style={{ color: '#888', fontSize: '.85rem' }} href={`/places/edit?key=${p._key}`}>
                  edit
                </a>
              </p>
            </div>
          </Col>
        ))}
      </Row>
    </>
  );
}
